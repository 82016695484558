import Toolbar from "@mui/material/Toolbar";
import Head from "next/head";
import { Fragment } from "react";
import AdviceSearchFilters from "../../components/advice/AdviceSearchFilters/AdviceSearchFilters";
import AdviceTrendingTopics from "../../components/advice/AdviceTrendingTopics/AdviceTrendingTopics";
import { ContainerStyled } from "../../components/advice/styles";
import ToResolveClassNameError from "../../components/hoc/ToResolveClassNameError";
import SharedChat from "../../components/shared/SharedChat/SharedChat";
import SharedFooter from "../../components/shared/SharedFooter/SharedFooter";
import SharedNavbar2 from "../../components/shared/SharedNavbar/Navbar/Navbar";
import * as config from "../../config/config";

function Advice(props) {
  const metaTitle = "Career Advice for Malaysian Professionals | Hiredly";
  const metaDescription =
    "Career Advice for Malaysian Professionals. No matter your industry or background, Hiredly makes job searching easy and fast.";

  return (
    <Fragment>
      <Head>
        {/* <!-- Primary Meta Tags --> */}
        <title>{metaTitle}</title>
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" key={"ogType"} />
        <meta
          property="og:url"
          content="https://my.hiredly.com/advice"
          key="ogUrl"
        />
        <meta property="og:title" content={metaTitle} key="ogTitle" />
        <meta
          property="og:description"
          content={metaDescription}
          key="ogDescription"
        />
        <meta
          property="og:image"
          content={
            config.assetDomain + "/images/hiredly/hiredly-meta-image-v1.png"
          }
          key="ogImage"
        />

        {/* <!-- Twitter --> */}
        <meta
          property="twitter:card"
          content="summary_large_image"
          key="twitterCard"
        />
        <meta
          property="twitter:url"
          content="https://my.hiredly.com/advice"
          key="twitterUrl"
        />
        <meta property="twitter:title" content={metaTitle} key="twitterTitle" />
        <meta
          property="twitter:description"
          content={metaDescription}
          key="twitterDescription"
        />
        <meta
          property="twitter:image"
          content={
            config.assetDomain + "/images/hiredly/hiredly-meta-image-v1.png"
          }
          key="twitterImage"
        />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link rel="canonical" href="https://my.hiredly.com/advice" />
      </Head>
      <ToResolveClassNameError>
        <SharedNavbar2 />
        <Toolbar />
        <AdviceSearchFilters />
        <ContainerStyled>
          <AdviceTrendingTopics />
        </ContainerStyled>
        <SharedFooter />
        <SharedChat />
      </ToResolveClassNameError>
    </Fragment>
  );
}

export default Advice;
