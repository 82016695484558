import DoneIcon from "@mui/icons-material/Done";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import withTheme from "@mui/styles/withTheme";
import styled from "styled-components";
import Color from "../../../assets/colors";

// Grid Components //
export const GridTitle = styling(Grid)({
  margin: "1.25rem 0",
});

export const GridTopicCardsContainer = styling(Grid)((props) => ({
  columnCount: "3",

  [props.theme.breakpoints.down(780)]: {
    columnCount: "2",
  },

  [props.theme.breakpoints.down(480)]: {
    columnCount: "1",
  },
}));

export const ContainerStyled = styling(Grid)({
  marginBottom: "2.5rem",
});

export const GridChips = withTheme(styled(Grid)`
  && {
    display: flex;
    overflow-y: auto;
    margin: ${(props) =>
      props.$no_margin_top ? "2rem 0 0 0" : "4.5rem 0 3rem 0"};
    padding: 0 0 0.75rem 0;

    /* ScrollBar */
    &::-webkit-scrollbar {
      height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 6.25rem;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: ${Color.secondaryGrey};
      border-radius: 6.25rem;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      justify-content: space-between;
      margin: 1.876rem 0;
    }
  }
`);

// Typography Components //
export const TitleStyled = styled(Typography)`
  && {
    font-weight: bold;
    text-transform: ${(props) => (props.$search_results ? null : "capitalize")};
    font-size: 2rem;
  }
`;

export const NoSearchResults = styling(Typography)({
  fontWeight: "bold",
  fontSize: "1.5625rem",
  marginBottom: "1rem",
});

export const NoSearchResultsSubtitle = styling(Typography)({
  fontSize: "1rem",
  marginBottom: "2.25rem",
});

export const JobTitle = withTheme(styled(Typography)`
  && {
    font-weight: bold;
    font-size: 1rem;
    text-transform: capitalize;

    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: break-spaces;

    ${(props) => props.theme.breakpoints.down("tabletS")} {
      -webkit-line-clamp: 1;
    }
  }
`);

export const ArticleExcerpt = withTheme(styled(Typography)`
  && {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: break-spaces;

    ${(props) => props.theme.breakpoints.down("tabletS")} {
      min-height: 120px;
    }
  }
`);

// Card Components //
export const CardStyled = styling(Card)((props) => ({
  width: "fit-content",
  margin: "0.75rem",
  borderRadius: "1.875rem",
  height: "400px",
  background: "none",

  "& .MuiButtonBase-root": {
    flexDirection: "column",
    width: "min-content",
  },

  "&.MuiPaper-elevation1": {
    boxShadow: "none",
  },

  [props.theme.breakpoints.down(780)]: {
    "& .MuiButtonBase-root": {
      width: "100%",
    },
  },

  [props.theme.breakpoints.down(480)]: {
    "& .MuiButtonBase-root": {
      width: "100%",
    },
  },
}));

export const CardMediaStyled = styling(CardMedia)((props) => ({
  borderRadius: "1.875rem",
  height: "235px",
  width: "370px",

  [props.theme.breakpoints.down(780)]: {
    width: "100%",
    minWidth: "100%",
    maxWidth: "100%",
    minHeight: "200px",
    borderRadius: "0.625rem 0.625rem 0 0",
  },

  [props.theme.breakpoints.down(480)]: {
    width: "100%",
    minWidth: "100%",
    maxWidth: "100%",
    borderRadius: "0.625rem 0.625rem 0 0",
  },

  [props.theme.breakpoints.up("md")]: {
    width: "390px",
  },
}));

export const CardActionAreaStyled = styling(CardActionArea)((props) => ({
  width: "100%",
  height: "100%",
  display: "flex",

  [props.theme.breakpoints.down(780)]: {
    flexDirection: "column",
  },

  [props.theme.breakpoints.down(480)]: {
    flexDirection: "column",
  },
}));

export const CardContentStyled = styling(CardContent)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  paddingTop: "2.5rem",
  width: "100%",
  marginBottom: "auto",
});

// Misc. Components //
export const IconComponent = styling(DoneIcon)({
  marginRight: "0.5rem",
});

export const SubCategoryButton = withTheme(styled(Button)`
  && {
    text-transform: capitalize;
    margin-right: 3rem;
    color: ${(props) =>
      props.$selected_subcategory
        ? `${Color.hiredlyPurple}`
        : `${Color.black}`};
    border-bottom: ${(props) =>
      props.$selected_subcategory ? `2px solid` : null};
    border-radius: 0;

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      margin: 0;
      font-size: 0.75rem;
    }
  }
`);

export const SkeletonStyled = styling(Skeleton)((props) => ({
  margin: "0.75rem",
  width: "370px",
  height: "235px",
  borderRadius: "1.875rem",

  [props.theme.breakpoints.down(480)]: {
    minWidth: "100%",
    margin: "0 0 0.5rem 0",
  },

  [props.theme.breakpoints.down(770)]: {
    width: "350px",
  },

  [props.theme.breakpoints.up(960)]: {
    width: "390px",
  },
}));

export const ChipStyled = withTheme(styled(Chip)`
  && {
    margin-left: ${(props) => (props.$all ? "0" : "0.5rem")};
    height: 2.5rem;
    border-radius: 1.25rem;
    padding: 0 1rem;

    & .MuiChip-label {
      font-size: 0.75rem;
      font-weight: bold;

      ${(props) => props.theme.breakpoints.down("mobileL")} {
        font-size: 1rem;
        height: auto;
      }
    }
  }
`);

export const SpanStyled = styled.span`
  text-transform: lowercase;
`;

export const LinkStyled = styling(Link)({
  textDecoration: "none",
});
