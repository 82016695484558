import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSpring } from "react-spring";
import * as config from "../../../config/config";
import {
  clearPagination,
  getBlogs,
  updateSearchQuery,
  updateSelectedCategory,
  updateSelectedSubcategory,
  updateStaticSearchQuery,
} from "../../../redux/actions/advice_action";
import {
  ContainerStyled,
  GridContainer,
  GridFilterContainer,
  GridFilters,
  GridTitle,
  HeaderImageStyled,
  IconButtonStyled,
  InputBaseStyled,
  SearchIconStyled,
  SearchbarStyled,
  TitleStyled,
} from "./styles";

export default function AdviceSearchFilters(props) {
  const searchQuery = useSelector((state: any) => state?.advice?.searchQuery);
  const dispatch: any = useDispatch();

  // React Spring animation for title text
  const titleAnimateFirst = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 700,
    config: { duration: 1000 },
  });

  function onSubmit() {
    dispatch(clearPagination());
    dispatch(updateSelectedCategory({}));
    dispatch(updateSelectedSubcategory({}));
    let params = {
      searchQuery: searchQuery,
    };
    dispatch(getBlogs(params));
    dispatch(updateStaticSearchQuery(searchQuery));
  }

  function onSearchQueryChange(event) {
    dispatch(updateSearchQuery(event.target.value));
  }

  function onKeyDown(event) {
    if (event.key === "Enter") {
      onSubmit();
    }
  }

  return (
    <Fragment>
      <ContainerStyled>
        <HeaderImageStyled
          alt="advice-page-header-image"
          src={config.assetDomain + "/images/hiredly/advice-header-image.jpg"}
        />
        <GridFilters>
          <GridFilterContainer>
            <GridTitle>
              <TitleStyled style={titleAnimateFirst} variant="h1">
                advice for every stage in your career{" "}
              </TitleStyled>
            </GridTitle>
          </GridFilterContainer>
          <GridContainer>
            <SearchbarStyled elevation={0}>
              <InputBaseStyled
                placeholder={"Search for articles by category or content"}
                onChange={onSearchQueryChange}
                onKeyDown={onKeyDown}
                value={searchQuery}
              />
              <IconButtonStyled
                size="small"
                aria-label="search"
                onClick={onSubmit}
              >
                <SearchIconStyled />
              </IconButtonStyled>
            </SearchbarStyled>
          </GridContainer>
        </GridFilters>
      </ContainerStyled>
    </Fragment>
  );
}
