import Grid from "@mui/material/Grid";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useTriggerSnackbar from "../../../hooks/useTriggerSnackbar";
import {
  clearPagination,
  getArticleCategories,
  getBlogs,
  requestArticle,
  updateSearchQuery,
  updateSelectedCategory,
  updateSelectedSubcategory,
  updateStaticSearchQuery,
} from "../../../redux/actions/advice_action";
import { getUser } from "../../../redux/actions/user_action";
import Button from "../../shared/SharedButton/SharedButton";
import {
  ArticleExcerpt,
  CardActionAreaStyled,
  CardContentStyled,
  CardMediaStyled,
  CardStyled,
  ChipStyled,
  ContainerStyled,
  GridChips,
  GridTitle,
  GridTopicCardsContainer,
  IconComponent,
  JobTitle,
  LinkStyled,
  NoSearchResults,
  NoSearchResultsSubtitle,
  SkeletonStyled,
  SubCategoryButton,
  TitleStyled,
} from "./styles";

export default function AdviceTrendingTopics(props) {
  const dispatch: any = useDispatch();
  const fetchingBlogs = useSelector(
    (state: any) => state?.advice?.fetchingBlogs
  );
  const blogs = useSelector((state: any) => state?.advice?.blogs);
  const categories = useSelector((state: any) => state?.advice?.categories);
  const selectedCategory = useSelector(
    (state: any) => state?.advice?.selectedCategory
  );
  const selectedSubcategory = useSelector(
    (state: any) => state?.advice?.selectedSubcategory
  );
  const staticSearchQuery = useSelector(
    (state: any) => state?.advice?.staticSearchQuery
  );
  // TODO: Add button loading indicator for "loading" state
  const [requestArticleStatus, setRequestArticleStatus] = useState<
    "pending" | "loading" | "success"
  >("pending");
  const user = getUser();
  const [triggerSnackbarFunc] = useTriggerSnackbar();

  function handleCategoryClick(category) {
    if (
      category.attributes?.categoryId ===
      selectedCategory.attributes?.categoryId
    ) {
      return;
    }
    dispatch(updateSelectedCategory(category));
    // Set default selected subcategory to the first one
    const firstSubcategory = category.attributes?.articleSubcategories?.data[0];
    dispatch(updateSelectedSubcategory(firstSubcategory));

    // Clean up logic
    dispatch(clearPagination());
    dispatch(updateSearchQuery(""));
    dispatch(updateStaticSearchQuery(""));

    const params = {
      categoryId: category.attributes?.categoryId,
      subcategoryId: firstSubcategory?.attributes?.subcategoryId,
    };
    dispatch(getBlogs(params));
  }

  function handleSubcategoryClick(subcategory) {
    if (
      subcategory.attributes?.subcategoryId ===
      selectedSubcategory.attributes?.subcategoryId
    ) {
      return;
    }

    dispatch(updateSelectedSubcategory(subcategory));
    dispatch(clearPagination());
    let params = {
      categoryId: selectedCategory.attributes?.categoryId ?? "",
      subcategoryId: subcategory.attributes?.subcategoryId,
    };
    dispatch(getBlogs(params));
  }

  function handleRequestTopicClick() {
    setRequestArticleStatus("loading");
    if (user) {
      const params = {
        canCancel: false,
        email: user.email,
        articleTitle: staticSearchQuery,
      };
      dispatch(requestArticle(params)).then((res) => {
        if (res.success) {
          triggerSnackbarFunc({
            snackbarMessage: "Your article has been successfully requested!",
            severity: "success",
          });
        } else {
          triggerSnackbarFunc({
            snackbarMessage: "Something went wrong, please try again later.",
            severity: "error",
          });
        }
      });
    } else {
      setRequestArticleStatus("pending");
      triggerSnackbarFunc({
        snackbarMessage: "Please log in to request for articles.",
        severity: "error",
      });
    }
  }

  // Infinite scrolling pagination
  // useCallback to update the category whenever it change
  const onScroll = useCallback(() => {
    let params = {
      categoryId: selectedCategory.attributes?.categoryId ?? "",
      subcategoryId: selectedSubcategory.attributes?.subcategoryId ?? "",
    };

    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 200
    ) {
      dispatch(getBlogs(params));
    }
  }, [selectedCategory, selectedSubcategory, dispatch]);

  useEffect(() => {
    dispatch(clearPagination());
    dispatch(getArticleCategories());
    dispatch(getBlogs());
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [onScroll]);

  return (
    <ContainerStyled>
      <GridChips>
        <ChipStyled
          id={"blog-category-filter-button"}
          $all
          label="All"
          onClick={() => handleCategoryClick({ categoryId: null })}
          variant="default"
          style={
            !selectedCategory.attributes?.categoryId
              ? { background: "black", color: "white" }
              : {}
          }
        />
        {categories.map((category, index) => (
          <ChipStyled
            id={"blog-category-filter-button"}
            label={category.attributes?.category}
            onClick={() => handleCategoryClick(category)}
            variant="outlined"
            key={index}
            value={category.attributes?.categoryId}
            style={
              selectedCategory.attributes?.categoryId ==
              category.attributes?.categoryId
                ? { background: "black", color: "white" }
                : {}
            }
          />
        ))}
      </GridChips>
      <GridTitle>
        {blogs.length === 0 && !fetchingBlogs ? (
          // No results found
          <div>
            <NoSearchResults>
              Sorry, we couldn't find any match for "{staticSearchQuery}"
            </NoSearchResults>
            <NoSearchResultsSubtitle>
              Request for this topic and we will work on it! You will be
              notified through email once this topic becomes available.
            </NoSearchResultsSubtitle>
            <Button
              button_type={"SolidPurple"}
              desktop_width={"Medium"}
              text_transform={"capitalize"}
              // TODO: resolve TypeScript type error
              // @ts-ignore
              variant="contained"
              color="primary"
              onClick={handleRequestTopicClick}
              disabled={requestArticleStatus === "loading"}
            >
              {requestArticleStatus === "success" ? (
                <>
                  <IconComponent />
                  <span>Topic Requested</span>
                </>
              ) : (
                "Request Topic"
              )}
            </Button>
          </div>
        ) : staticSearchQuery ? (
          <TitleStyled $search_results>
            Search results for "{staticSearchQuery}"
          </TitleStyled>
        ) : !selectedCategory.attributes?.categoryId ? (
          <TitleStyled>What Do You Want to Work on Today?</TitleStyled>
        ) : (
          <TitleStyled>{selectedCategory.attributes?.category}</TitleStyled>
        )}
        <GridChips $no_margin_top>
          {selectedCategory.attributes?.articleSubcategories?.data?.map(
            (value, index) => {
              return (
                <SubCategoryButton
                  onClick={() => handleSubcategoryClick(value)}
                  variant="text"
                  $selected_subcategory={
                    selectedSubcategory.attributes?.subcategoryId ==
                    value.attributes?.subcategoryId
                  }
                  key={index}
                >
                  {value.attributes?.subcategory}
                </SubCategoryButton>
              );
            }
          )}
        </GridChips>
      </GridTitle>
      <GridTopicCardsContainer container>
        {!fetchingBlogs || blogs.length > 0
          ? // Has results
            blogs.map((blog) => (
              <Grid
                key={blog?.attributes?.articleId}
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={4}
              >
                <LinkStyled
                  href={`/advice/${blog?.attributes?.slug}`}
                  id={blog?.attributes?.articleId}
                >
                  <CardStyled
                    id={blog?.attributes?.articleId}
                    key={blog?.attributes?.articleId}
                  >
                    <CardActionAreaStyled>
                      <CardMediaStyled
                        image={
                          blog?.attributes?.coverImage?.data?.attributes?.url
                        }
                        title="advice-image"
                      />
                      <CardContentStyled>
                        <JobTitle gutterBottom variant="h5" component="h2">
                          {blog?.attributes?.title}
                        </JobTitle>
                        <ArticleExcerpt
                          variant="body2"
                          color="textSecondary"
                          component="p"
                          dangerouslySetInnerHTML={{
                            __html: blog?.attributes?.excerpt,
                          }}
                        ></ArticleExcerpt>
                      </CardContentStyled>
                    </CardActionAreaStyled>
                  </CardStyled>
                </LinkStyled>
              </Grid>
            ))
          : Array.from(new Array(6)).map((array, index) => {
              return (
                <Grid key={index} item xs={12} sm={6} md={6} lg={4} xl={4}>
                  <SkeletonStyled variant="rectangular" />
                </Grid>
              );
            })}
        {/* Loading state for next page */}
        {fetchingBlogs &&
          blogs.length > 0 &&
          Array.from(new Array(6)).map((e, index) => (
            <Grid key={index} item xs={12} sm={6} md={6} lg={4} xl={4}>
              <SkeletonStyled variant="rectangular" />
            </Grid>
          ))}
      </GridTopicCardsContainer>
    </ContainerStyled>
  );
}
