import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import { animated } from "react-spring";
import styled from "styled-components";
import Color from "../../../assets/colors";

// Grid Components //
export const GridFilters = styling(Grid)((props) => ({
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  position: "absolute",
  top: "0",
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",

  [props.theme.breakpoints.down(480)]: {
    padding: "0.625px",
  },
}));

export const GridFilterContainer = styling(Grid)({
  flexDirection: "column",
  justifyContent: "center",
  height: "100%",
  display: "flex",
});

export const GridTitle = styling(Grid)({
  textAlign: "center",
});

export const GridContainer = styling(Grid)({
  position: "absolute",
  bottom: "0",
  transform: "translate(0, 50%)",
  width: "55%",
});

// Misc. Components //
export const TitleStyled = styling(animated(Typography))((props) => ({
  textTransform: "uppercase",
  color: Color.white,
  fontWeight: "bold",
  fontFamily: `"Poppins", sans-serif`,
  lineHeight: "1.25em",
  fontSize: "3rem",

  [props.theme.breakpoints.down(768)]: {
    fontSize: "30px",
  },
}));

export const HeaderImageStyled = styled.img`
  && {
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: 0 30%;
  }
`;

export const ContainerStyled = styling(Box)({
  position: "relative",
  height: "45vh",
});

export const SearchIconStyled = styling(SearchIcon)({
  height: "0.8em",
  width: "0.8em",
  fontSize: "1.45rem",
});

export const SearchbarStyled = styling(Paper)({
  cursor: "pointer",
  borderRadius: "1.875rem",
  paddingLeft: "1.5625rem",
  display: "flex",
  justifyContent: "space-between",
  height: "54px",
  alignItems: "center",
  boxShadow: "0 2px 5px 0 rgb(0 0 0 / 15%)",
});

export const IconButtonStyled = styling(IconButton)({
  backgroundColor: Color.hiredlyPurple,
  color: Color.white,
  margin: "0.75rem",
  borderRadius: "50%",
  height: "2.5rem",
  width: "2.5rem",

  "&:hover": {
    backgroundColor: Color.black,
  },
});

export const InputBaseStyled = styling(InputBase)((props) => ({
  marginTop: "0.125rem",
  width: "100%",

  [props.theme.breakpoints.up(480)]: {
    width: "90%",
  },
}));
